export const LOGOS = {
  BRAND: {
    LIGHT: {
      icon: "/images/logos/grentone/icon.png",
      small: "/images/logos/grentone/light.svg",
      medium: "/images/logos/grentone/light.svg",
      large: "/images/logos/grentone/light.svg",
    },
    DARK: {
      icon: "/images/logos/grentone/icon.png",
      small: "/images/logos/grentone/dark.svg",
      medium: "/images/logos/grentone/dark.svg",
      large: "/images/logos/grentone/dark.svg",
    },
  },
  BROKER1: {
    LIGHT: {
      icon: "/images/logos/grentone/icon.png",
      small: "/images/logos/grentone/light.svg",
      medium: "/images/logos/grentone/light.svg",
      large: "/images/logos/grentone/light.svg",
    },
    DARK: {
      icon: "/images/logos/grentone/icon.png",
      small: "/images/logos/grentone/dark.svg",
      medium: "/images/logos/grentone/dark.svg",
      large: "/images/logos/grentone/dark.svg",
    },
  },
  BROKER2: {
    LIGHT: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
    DARK: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
  },
};
